import React from "react";

const Partner = () => {
    return (
        <div className="partner-container" data-v-7c653112>
            <div className="line" data-v-7c653112>
            <img src="/_nuxt/img/Divider.64e9d59.png" alt="" data-v-7c653112 />
            </div>
            <div className="partner-content" data-v-7c653112>
            <h2 data-v-7c653112>Our partners</h2>
            </div>
            <div className="container" data-v-7c653112>
                <div
                    className="row row-cols-2 row-cols-md-3 row-cols-lg-5 g-2 g-lg-3 justify-content-center"
                    data-v-7c653112
                >
                    <div className="col" data-v-7c653112>
                    <a href="https://sonosuite.com" data-v-7c653112>
                        <img
                        src="/img/partner/sono.png"
                        alt="Sonosuite"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.ascap.com" data-v-7c653112>
                        <img
                        src="/img/partner/ascap.png"
                        alt="ASCAP"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.linkfire.com" data-v-7c653112>
                        <img
                        src="/img/partner/linkfire.png"
                        alt="Linkfire"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.musixmatch.com" data-v-7c653112>
                        <img
                        src="/img/partner/musixmatch.png"
                        alt="Musixmatch"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://songstats.com" data-v-7c653112>
                        <img
                        src="/img/partner/songstats.png"
                        alt="Songstats"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://amis.misa.vn/amis-wesign" data-v-7c653112>
                        <img
                        src="/img/partner/amis wesign.png"
                        alt="AMIS WeSign"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://zingmp3.vn" data-v-7c653112>
                        <img
                        src="/img/partner/zing mp3.png"
                        alt="Zing Mp3"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.nhaccuatui.com" data-v-7c653112>
                        <img
                        src="/img/partner/nct.png"
                        alt="Nhaccuatui"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                    <div className="col" data-v-7c653112>
                    <a href="https://www.vcpmc.org" data-v-7c653112>
                        <img
                        src="/img/partner/vcpmc.png"
                        alt="VCPMC"
                        data-v-7c653112
                        />
                    </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partner;