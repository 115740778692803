export const ARTIST_DATA = [
  {
    id: 1,
    image: "/img/artists/pham-sac-lenh.jpg",
    name: "PHẠM SẮC LỆNH",
    url: "https://www.facebook.com/phamsaclenh97",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/phamsaclenh97",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UCmXMhLkoy2FDvmtw0mL3gsA",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@73qblenh",
      },
    ],
  },
  {
    id: 2,
    image: "/img/artists/vidhia-r.jpg",
    name: "VIDHIA R",
    url: "https://www.facebook.com/fidhiyah.rahmawati",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/fidhiyah.rahmawati",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/@Vidhi.Official",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@vidhia_r",
      },
    ],
  },
  {
    id: 3,
    image: "/img/artists/quang-hao.jpg",
    name: "QUANG HẢO",
    url: "https://www.facebook.com/bigzero.bigzero",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/bigzero.bigzero",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UCL7BfxZKM6Agv84e70HWZEQ",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@haocachua",
      },
    ],
  },
  {
    id: 4,
    image: "/img/artists/dat-villa.jpg",
    name: "ĐẠT VILLA",
    url: "https://www.facebook.com/datvilla94",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/datvilla94",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/@Datvilla1994",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@datvilla94",
      },
    ],
  },
  {
    id: 5,
    image: "/img/artists/ho-nhat-huy.jpg",
    name: "HỒ NHẬT HUY",
    url: "https://www.facebook.com/david.tuananh.9210",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/david.tuananh.9210",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UCJZt5mzo7FoAMkaaGX5zJTg",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@honhathuy73qb",
      },
    ],
  },
  {
    id: 6,
    image: "/img/artists/thien-an.jpg",
    name: "THIÊN ÂN",
    url: "https://www.facebook.com/thienan.cs",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/thienan.cs",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UCTJsTKnabko63_E-Wy5IllA",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@vietau2000",
      },
    ],
  },
  {
    id: 7,
    image: "/img/artists/ngoc-kayla.jpg",
    name: "NGỌC KAYLA",
    url: "https://www.facebook.com/kayla.ngoc.714",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/kayla.ngoc.714",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/@ngockaylaofficial",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@ngockayla.official",
      },
    ],
  },
  {
    id: 8,
    image: "/img/artists/nam-he.jpg",
    name: "NAM HEE",
    url: "https://www.facebook.com/namhee1609",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/namhee1609",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UC7CBf5uWLaYQPzLZP6cPGxQ",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@namheevkc",
      },
    ],
  },
  {
    id: 9,
    image: "/img/artists/datr.jpg",
    name: "ĐẠT R",
    url: "https://www.facebook.com/datRmix1511",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/datRmix1511",
      },
    ],
  },
  {
    id: 10,
    image: "/img/artists/lqremix.jpg",
    name: "LQ REMIX",
    url: "https://www.facebook.com/LQmedia73",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/LQmedia73",
      },
    ],
  },
  {
    id: 11,
    image: "/img/artists/nhatdoan.jpg",
    name: "NHẬT ĐOÀN",
    url: "https://www.facebook.com/nhatqud",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/nhatqud",
      },
    ],
  },
  {
    id: 12,
    image: "/img/artists/phongle.jpg",
    name: "PHONG LÊ",
    url: "https://www.facebook.com/phongle241201",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/phongle241201",
      },
    ],
  },  
  {
    id: 13,
    image: "/img/artists/quyenquyen.jfif",
    name: "QUYÊN QUYÊN",
    url: "https://www.facebook.com/QuyenQuyenMC",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/QuyenQuyenMC",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/@quyenquyen.official",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@mcquyenquyen",
      },
    ],
  },
];
export const ARTIST_TOP_DATA = [
  {
    id: 1,
    image: "/img/artists/pham-sac-lenh.jpg",
    name: "PHẠM SẮC LỆNH",
    url: "https://www.facebook.com/phamsaclenh97",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/phamsaclenh97",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UCmXMhLkoy2FDvmtw0mL3gsA",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@73qblenh",
      },
    ],
  },
  {
    id: 2,
    image: "/img/artists/vidhia-r.jpg",
    name: "VIDHIA R",
    url: "https://www.facebook.com/fidhiyah.rahmawati",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/fidhiyah.rahmawati",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/@Vidhi.Official",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@vidhia_r",
      },
    ],
  },
  {
    id: 3,
    image: "/img/artists/quang-hao.jpg",
    name: "QUANG HẢO",
    url: "https://www.facebook.com/bigzero.bigzero",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/bigzero.bigzero",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UCL7BfxZKM6Agv84e70HWZEQ",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@haocachua",
      },
    ],
  },
  {
    id: 4,
    image: "/img/artists/dat-villa.jpg",
    name: "ĐẠT VILLA",
    url: "https://www.facebook.com/datvilla94",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/datvilla94",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/@Datvilla1994",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@datvilla94",
      },
    ],
  },
  {
    id: 5,
    image: "/img/artists/ho-nhat-huy.jpg",
    name: "HỒ NHẬT HUY",
    url: "https://www.facebook.com/david.tuananh.9210",
    jobs: ["Nghệ sĩ", "Composer", "Singer"],
    music_list: ["Hiphop", "Rap", "RnB"],
    socials: [
      {
        type: "facebook",
        url: "https://www.facebook.com/david.tuananh.9210",
      },
      {
        type: "youtube",
        url: "https://www.youtube.com/channel/UCJZt5mzo7FoAMkaaGX5zJTg",
      },
      {
        type: "tiktok",
        url: "https://www.tiktok.com/@honhathuy73qb",
      },
    ],
  },
];
